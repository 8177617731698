<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="'over'"
      [opened]="false">
    <mat-toolbar>Logarithm Labs</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Home</a>
      <a mat-list-item href="#">Data Ingest</a>
      <a mat-list-item href="#">Project Settings</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Portal</span>
    </mat-toolbar>
    <!-- Add Content Here -->
    <!-- TODO: Change div height in SCSS file -->
    <!-- TODO: Add router-outlet to this place -->
    <div style="height: 90vh;">
      <app-dashboard></app-dashboard>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
